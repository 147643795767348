<template>
    <div>
        <Loader v-if="isLoading" />
        <form id="st-edit-notification-template-form" class="form">
            <div class="row gutter-b align-items-center">
                <div class="col-12 col-md-6">
                    <div class="d-flex">
                        <div>
                            <b-button 
                                variant="white" 
                                pill 
                                class="pl-3 pr-2"
                                @click="cancel"
                            >
                                <i class="fa fa-arrow-left"></i>
                            </b-button>
                        </div>
                        <div class="pl-4 d-flex align-items-center">
                            <h6 class="m-0">{{ $t('NOTIFICATIONS.TEMPLATES.EDIT.PAGE_TITLE') }}</h6>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6 text-right">
                    <st-button
                        v-can:edit="'notificationsTemplate'"
                        :callback="submit"
                        size="large"
                    >
                        {{ $t('NOTIFICATIONS.TEMPLATES.EDIT.SAVE') }}
                    </st-button>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <b-form-group
                        label-cols-md="2"
                        content-cols-md="7"
                        :label="$t('NOTIFICATIONS.TEMPLATES.EDIT.LANGUAGE')"
                        class="font-size-h6"
                    >
                        <b-form-radio-group
                            id="btn-radios-2"
                            v-model="template.language"
                            :options="options"
                            button-variant="outline-primary"
                            name="radio-btn-outline"
                            buttons
                        ></b-form-radio-group>
                    </b-form-group>
                        
                    <b-form-group 
                        :label="$t('NOTIFICATIONS.TEMPLATES.EDIT.NAME')"
                        class="font-size-h6"
                    >
                        <st-input-text
                            v-model="template.title"
                            ref="title"
                            name="title"
                        />
                    </b-form-group>

                    <div class="font-size-h6">
                        {{ $t('NOTIFICATIONS.TEMPLATES.EDIT.CHANNEL') }}
                    </div>
                    <div> 
                        <div class="d-inline-block pr-6">
                            <b-form-radio
                                size="lg"
                                value="sms"
                                v-model="template.channel"
                                :disabled="true"
                            >
                                {{ $t('NOTIFICATIONS.TEMPLATES.EDIT.SMS') }}
                            </b-form-radio>
                        </div>
                        <div class="d-inline-block pr-6">
                            <b-form-radio
                                size="lg"
                                value="email"
                                v-model="template.channel"
                                :disabled="true"
                            >
                                {{ $t('NOTIFICATIONS.TEMPLATES.EDIT.EMAIL') }}
                            </b-form-radio>
                        </div>
                        <div class="d-inline-block">
                            <b-form-radio
                                size="lg"
                                value="in-app"
                                v-model="template.channel"
                                :disabled="true"
                            >
                                {{ $t('NOTIFICATIONS.TEMPLATES.EDIT.IN_APP') }}
                            </b-form-radio>
                        </div>
                    </div>

                    <st-info-alert 
                        :title="$t('NOTIFICATIONS.TEMPLATES.EDIT.ACCEPTED_PLACEHOLDERS')"
                        :text="placeholders"    
                    />

                    <sms-notification-template 
                        v-if="template.channel === 'sms'"
                        :message="template.message"
                        @message="template.message = $event"
                    />

                    <email-notification-template 
                        v-if="template.channel === 'email'"
                        :subject="template.subject"
                        @subject="template.subject = $event"
                        :message="template.message"
                        @message="template.message = $event"
                    />

                    <in-app-notification-template 
                        v-if="template.channel === 'in-app'"
                        :subject="template.subject"
                        @subject="template.subject = $event"
                        :message="template.message"
                        @message="template.message = $event"
                    />

                </div>
            </div>
        </form>
    </div>
</template>

<script>
import KTUtil from '@/assets/js/components/util';
import formValidation from '@/assets/plugins/formvalidation/dist/es6/core/Core';

// FormValidation plugins
import Trigger from '@/assets/plugins/formvalidation/dist/es6/plugins/Trigger';
import Bootstrap from '@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap';

import EmailNotificationTemplate from './EmailNotificationTemplate';
import SmsNotificationTemplate from './SmsNotificationTemplate';
import InAppNotificationTemplate from './InAppNotificationTemplate';

import { mapActions, mapGetters } from 'vuex';

export default {
    name: 'EditNotificationTemplate',
    components: {
        EmailNotificationTemplate,
        SmsNotificationTemplate,
        InAppNotificationTemplate,
    },
    beforeCreate() {
        this.data = this.$route.meta.data;
    },
    data() {
        return {
            options: [
                { 
                    value: 'ro', 
                    text: this.$t('NOTIFICATIONS.TEMPLATES.EDIT.RO'),
                    disabled: this.data.language !== 'ro',
                },
                { 
                    value: 'en', 
                    text: this.$t('NOTIFICATIONS.TEMPLATES.EDIT.EN'),
                    disabled: this.data.language !== 'en',
                },
                { 
                    value: 'hu', 
                    text: this.$t('NOTIFICATIONS.TEMPLATES.EDIT.HU'),
                    disabled: this.data.language !== 'hu',
                },
            ],
            template: { ...this.data },
        }
    },
    methods: {
        ...mapActions({
            updateNotificationTemplate: 'notifications/templates/updateNotificationTemplate',
        }),
        async submit() {
            try {
                const validateForm = await this.fv.validate();
                const valid = validateForm === 'Valid';
                
                if (valid) {
                    await this.updateNotificationTemplate(this.template);
                    this.$router.push({ name: 'notifications-templates' });
                }
            } catch(err) {
                this.$notify({ icon: 'error' });
            }
        },
        cancel() {
            this.$router.back();
        },
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
        }),
        placeholders() {
            return this.template.allowed_variables
                .map(placeholder => `{{{${placeholder}}}}`)
                .join(', ');
        },
        isLoading() {
            return this.loading['notifications/templates/updateNotificationTemplate'];
        },
    },
    mounted() {
        const notEmpty = {
            message: this.$t('VALIDATION.REQUIRED_FIELD'),
        };

        const form = KTUtil.getById('st-edit-notification-template-form');
        
        this.fv = formValidation(form, {
            fields: {
                title: {
                    validators: {
                        notEmpty,
                    },
                },
                subject: {
                    validators: {
                        notEmpty,
                    },
                },
                message: {
                    validators: {
                        notEmpty,
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                bootstrap: new Bootstrap(),
            },
        });
    },
}
</script>