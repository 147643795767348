<template>
    <div>
        <b-form-group>
            <b-form-textarea
                v-model="messageVal"
                rows="8"
                no-resize
                name="message"
                :maxlength="limit"
            />
        </b-form-group>
        <span>{{ maxChars }} {{ remainingChars }}</span>
    </div>
</template>

<script>
export default {
    name: 'SmsNotificationTemplate',
    props: {
        message: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            limit: 160,
        }
    },
    computed: {
        messageVal: {
            get() {
                return this.message;
            },
            set(val) {
                this.$emit('message', val);
            },
        },
        maxChars() {
            return this.$t('NOTIFICATIONS.TEMPLATES.EDIT.MAX_CHARS', { chars: this.limit });
        },
        remainingChars() {
            const remaining = this.limit - this.messageVal.length;
            return this.$t('NOTIFICATIONS.TEMPLATES.EDIT.REMAINING_CHARS', { chars: remaining });
        },
    },
}
</script>
